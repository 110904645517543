<template>
  <div class="comment" v-if="data">
    <g-loading :loading="loading" />
    <div class="info">
      <div class="img">
        <img v-if="data.avatar_url" width="100%" height="100%" :src="data.avatar_url"/>
        <img v-else width="100%" height="100%" src="../../assets/img/online/doctor.png"/>
      </div>
      <div class="name">
        {{data.doctor_name}} {{data.doctor_title_label}}
        <br/>
         {{data.department}}
      </div>
      <div class="care">关注</div>
    </div>
    <div class="serve">
      <span>本次服务如何？</span>
      <van-rate
        class="icon"
        v-model="data.score"
        :size="20"
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
        />
    </div>
    <div class="input-area">
      <textarea class="input" :maxlength="150" placeholder="给医生点评下" v-model="data.content"/>
      <div class="num">{{data.content.length}}/150</div>
    </div>

    <div class="bt" @click="submit">提交</div>
  </div>
</template>

<script>
import {get_detail,to_submit} from './service'
import { Toast } from 'vant';

export default {
  data () {
    return {
      data: null,
      loading:false,
      form_data: {
        content: '',
        score: 0
      }
    }
  },
  computed: {
  },
  components: {

  },
  created () {
    this.search_detail ()
  },
  methods: {
    async search_detail () {
      let {session_id} = this.$route.query
      this.loading = true
      try {
        const {data} = await get_detail(session_id)
        this.data = {...this.form_data,...data}
      } finally {
        this.loading = false
      }
    },
    async submit () {
      let {session_id} = this.$route.query
      const{content,score} = this.data
      if(!score) {
        Toast({
          message: '请评分再提交',
          position: 'top',
        })
        return
      }
      this.loading = true
      try {
        await to_submit({session_id,content,score})
        this.$notify({ type: 'success', message: '评论成功！' })
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comment {
  padding:21px 10px;
  overflow: auto;
}
.info {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #5F5F5F;
  & .img {
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    opacity: ;
    border-radius: 50%;
    margin-right: 16px;
    & img{
      border-radius: 6px;
    }
  }
  & .name {
    flex: 1;
  }
  & .care {
    width: 60px;
    height: 28px;
    border: 1px solid #0088FF;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    color: #0088FF;
    text-align: center;
  }
}
.serve {
  display: flex;
  align-items: center;
  padding-top: 35px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  padding-bottom:22px;
  & span {
    flex:1;
  }
}
.input-area {
  width: 100%;
    height: 125px;
    background: #F7F8FA;
    border-radius: 7px;
    padding: 5px 11px;
    display: flex;
    flex-direction: column;
  & .input {
    flex: 1;
    background: #F7F8FA;
  }
  & .num {
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: rgba(200, 201, 204, 0.89);
  }
}
.bt {
  height: 36px;
  line-height: 36px;
  background: #1890FF;
  border-radius: 18px;
  margin: 24px 10px 0;
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
}
</style>
