import api from '../../utils/api'

// 获取详情
export async function get_detail (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         session_id: 1,
  //         doctor_name: '刘欢欢',
  //         doctor_title: '主任',
  //         doctor_title_label: '主任',
  //         department: '外科',
  //         score: 1,
  //         content: ''
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/session/comments/${payload}`)
}



export async function to_submit (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/inquiries/session/comments/${payload.session_id}`,payload)
}
